<template>
  <div class="dashboard-list-table">
    <div v-if="loader && localDashboards.length === 0" class="text-center">
      <Loader
        :size="'big'"
      />
    </div>
    <template v-else-if="user">
      <div class="card">
        <div class="card-body">
          <div class="table-responsive">
            <table class="table table-striped">
              <thead>
                <tr class="text-nowrap">
                  <th>{{ $t('name') }}</th>
                  <th width="100">
                    {{ $t('details') }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(dashboard, index) in localDashboards" :key="index" class="text-nowrap">
                  <td>
                    <router-link :to="{ name: 'dashboards.show', params: { id: dashboard.id } }" class="link-color3">
                      {{ dashboard.name }}
                    </router-link>
                  </td>
                  <td class="text-center">
                    <router-link :to="{ name: 'dashboards.show', params: { id: dashboard.id } }" class="link-color3"  v-b-tooltip.hover :title="$t('see_more')">
                      <i class="fa fa-eye"></i>
                    </router-link>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div class="text-center mt-4">
        <Button
          class="btn-primary btn-sm"
          :loading="loader"
          @click="loadMore"
          v-if="isLoadMoreVisible"
        >
          {{ $t('load_more') }}
        </Button>
      </div>
    </template>
  </div>
</template>

<script>

import DashboardsService from '@/modules/dashboards/services/dashboards-service'
import { mapState } from 'vuex'

import Button from '@/components/common/Button/Button'
import Loader from '@/components/common/Loader/Loader'

export default {
  name: 'DashboardsListTable',
  props: ['dashboards', 'reload'],
  components: {
    Button,
    Loader
  },
  data () {
    return {
      filters: {
      },
      localDashboards: [],
      loader: false,
      page: 1,
      isLoadMoreVisible: true,
      showIframeModal: false
    }
  },
  created () {
    if (this.dashboards) {
      this.localDashboards = this.dashboards
      this.isLoadMoreVisible = false
    } else {
      if (this.user) {
        this.getDashboards()
      }
    }
  },
  watch: {
    reload (newValue) {
      if (newValue) this.reset()
    },
    user (newUser) {
      this.getDashboards()
    }
  },
  computed: {
    ...mapState('users', {
      user: state => state.user
    })
  },
  methods: {
    /**
     * Get dashboards
     */
    async getDashboards () {
      if (!this.user) return false

      this.loader = true
      try {
        const dashboards = await DashboardsService.getDashboards({
          companyId: this.user.company.id,
          page: this.page
        })
        this.localDashboards = [...this.localDashboards, ...dashboards.data]
        this.isLoadMoreVisible = dashboards.meta.current_page !== dashboards.meta.last_page
      } catch (error) {
        error.handleGlobally && error.handleGlobally()
      }
      this.loader = false
    },
    /**
     * Load more
     */
    loadMore () {
      this.page++
      this.getDashboards()
    },
    /**
     * Reset and reload
     */
    reset () {
      this.page = 1
      this.localDashboards = []
      this.getDashboards()
    }
  }
}
</script>

<style lang="scss" scoped>
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
}
</style>
