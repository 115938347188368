<template>
  <b-modal
    id="dashboard-tutorial-modal"
    :modal-class="'default-modal'"
    :hide-footer="true"
  >
    <div class="text-center">
      <h2 class="highlighted-color1">
        <span>{{ $t('locate_your_dashboard') }}</span>
      </h2>
      <p>{{ $t('follow_the_steps_below') }}</p>
    </div>

    <div class="mt-3 default-form">
      <form class="default-form" @submit.prevent="">
        <iframe src="https://scribehow.com/embed/Acesse_seu_Dashboard_dentro_da_Pixel_Roads__d6k_SpnlQFGzkvOISMar8Q" width="100%" height="650" allowfullscreen frameborder="0"></iframe>
        <div class="mt-3 text-center">
          <Button
            class="btn btn-primary"
            @click="hide"
          >
            {{ $t('close') }}
          </Button>

        </div>
      </form>
    </div>
  </b-modal>
</template>

<script>

import Button from '@/components/common/Button/Button.vue'

export default {
  name: 'RequestDashboardModal',
  components: { Button },
  props: ['headerDescription', 'headerTitle'],
  methods: {
    hide () {
      this.$bvModal.hide('dashboard-tutorial-modal')
    }
  }
}
</script>

<style>

</style>
