<template>
  <div>
    <div class="d-flex justify-content-between align-items-center">
      <div>
        <h1 class="highlighted-color1">
          <span>{{ $t('dashboards') }}</span>
        </h1>
      </div>
      <div class="d-flex">
      </div>
    </div>
    <!-- DASHBOARDS -->
    <div class="mt-4">
      <DashboardListTable
        :reload="reload"
      />

      <div class="text-center mt-4">
        <Button
          class="btn-primary btn-sm"
          v-b-modal="'dashboard-tutorial-modal'"
        >
          {{ $t('questions_about_dashboard') }}
        </Button>
        <Button
          class="btn-sm ms-2 btn-color1"
          v-b-modal="'request-dashboard-modal'"
        > {{ $t('request_your_dashboard_here') }} </Button>

        <RequestDashboardModal />
        <DashboardTutorialModal />
      </div>
    </div> <!-- END DASHBOARDS -->
  </div>
</template>

<script>

import { mapState } from 'vuex'

import Button from '@/components/common/Button/Button'
import DashboardListTable from '@/components/dashboards/DashboardListTable'
import DashboardTutorialModal from '@/components/dashboards/DashboardTutorialModal'
import RequestDashboardModal from '@/components/dashboards/RequestDashboardModal'

export default {
  components: {
    Button,
    DashboardListTable,
    DashboardTutorialModal,
    RequestDashboardModal
  },
  data () {
    return {
      reload: false
    }
  },
  metaInfo () {
    return {
      title: this.$t('dashboards')
    }
  },
  computed: {
    ...mapState('users', {
      user: state => state.user
    })
  },
  methods: {
    /**
     * Handle upsert
     */
    handleUpsert (dashboard) {
      this.$router.push({ name: 'dashboards.show', params: { id: dashboard.id } })
    },
    /**
     * Reload dashboards
     */
    reloadDashboards () {
      this.reload = true
      setTimeout(() => {
        this.reload = false
      }, 300)
    }
  }
}
</script>
